import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
// import ProjectsList from "../components/ProjectsList"
import ClientFeedback from "../components/ClientFeedback"
import OutdoorImages from "../components/OutdoorImages"
import SEOmeta from "../components/SEOmeta"

const About = ({
  data: {
    allContentfulPortfolio: { nodes: projects },
  },
}) => {
  return (
    <Layout>
      <SEOmeta title="About" />
      <main className="page">
        <section className="about-page">
          <article className="about-info">
            <span className="about-subhead">Overview:</span>
            <p>
              <ul>
                <li>
                  Shaun is a Manual QA tester and Content Coordinator with 8
                  years experience.
                </li>
              </ul>
            </p>
            <span className="about-subhead">Recent work:</span>
            <p>
              <ul>
                <li>
                  Major contributor and driver in the timely launch of the
                  redesign of a website for a large Medical Industry Client.
                </li>
                <li>
                  Part of the team who drove the QA and Content facets of the
                  project.
                </li>
              </ul>
            </p>
            <span className="about-subhead"> Skills:</span>
            <p>
              <ul>
                <li>
                  Manage the coordination of website content, ensuring content
                  adheres to the client's design specifications.
                </li>
                <li>
                  Perform manual QA tests, report bugs and validate bug fixes.
                </li>
                <li>
                  Interface with the Creative and Development teams throughout
                  the course of projects.
                </li>
              </ul>
            </p>
          </article>
          <article>
            <StaticImage
              src="../assets/images/About.png"
              alt="Shaun Vine About Image"
              className="about-img"
              placeholder="blurred"
            />
          </article>
        </section>
        <section className="featured-projects">
          <ClientFeedback />
        </section>
        <section>
          <OutdoorImages />
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPortfolio(
      sort: { fields: title, order: ASC }
      filter: { featured: { eq: true }, title: {} }
    ) {
      nodes {
        id
        title
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
        }
      }
    }
  }
`

export default About
